import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService } from '@core/frontend-shared';


@Component({
	selector:'app-management-dynamic-redirect',
	template:`<ui-content-centered>
		<span>Bitte wählen Sie links im Menü Ihr Ziel.</span>
	</ui-content-centered>`,
})
export class AppManagementDynamicRedirectComponent {

	
	constructor(
		// private authService: AuthService, 
		private router: Router,
		private route: ActivatedRoute,
		private access:AccessService,
	) {

		const isCompanyManager = access.simpleAbilityCheck("Feature.common-users.Company.Manage")
		const isDocent = access.simpleAbilityCheck("Feature.mpf-docents.Manage")
		// console.log('ManagementDefaultRouteGuard check isManager',isManager)

		if (isCompanyManager) {
			this.router.navigate(['./manager'],{relativeTo:this.route})
		} else if (isDocent) {
			this.router.navigate(['./docent'],{relativeTo:this.route})
		}

	}

}